<template>
  <div class="InterfaceView">
    <!-- <el-card>
      <div slot="header">
        <h2>API 文档</h2>
      </div>
    </el-card> -->
    <!-- <el-button @click="transfer()">转账1USDT</el-button> -->

    <!-- <div v-html="htmlContent"></div> -->
    <iframe :src="htmlContent + 'api.html'"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      htmlContent: process.env.BASE_URL, // 存储 HTML 文件内容的变量
      form: {
        number: "",
      },
      dialogFormVisible: false,
      activeIndex: '1',
      selectedApi: null,
      apiList: [
        {
          id:'1',
          name: "查询资源使用",
          url: "https://peee.io/api/users",
          method: "POST",
          parameters: [
            { name: "apiKey", type: "test", description: "后台生成的apikey" },
            { name: "account", type: "test", description: "用户名" },
            { name: "pwd", type: "test", description: "密码" },
          ],
          responseExample: {
            code: 200 + "//请求成功结果状态码",
            msg: "ok // 请求结果信息",
            data: {
              userTrx: "0 // 用户余额，单位TRX",
              rentEnergy: 0,
              platformPrice: 60,
              apiPrice: 30,
              minOneEnergy: 32000,
            },
          },
        },
        {
          id:'2',
          name: "租用资源下单",
          url: "https://peee.io/energy/order/v2/rent",
          method: "POST",
          parameters: [
            { name: "apiKey", type: "test", description: "后台生成的apikey" },
            { name: "account", type: "test", description: "用户名" },
            { name: "pwd", type: "test", description: "密码" },
            { name: "resource_value", type: "32000", description: "资源数量" },
            { name: "receive_address", type: "", description: "能量接收地址" },
            {
              name: "rent_duration",
              type: "0",
              description: "能量租用时间，0表示一小时，大于0代表天数",
            },
          ],
          responseExample: {
            code: 200,
            msg: "ok",
            data: {
              orderId: "", // 订单号
              frozeTid: "", // 能量质押交易哈希
              receive_address: "", // 能量接收地址
              pay_amount: 30, // 消耗金额
              rent_duration: 0, // 能量租用时间，0表示一小时，大于0代表天数
              resource_value: 32000, // 能量租用数量
              rent_expire_time: "2024-09-09 00:00:00", // 能量到期时间
              remark: "成功",
              createTime: "2024-09-09 00:00:00", // 订单创建时间
            },
          },
        },
      ],
    };
  },
  computed: {
    globalConfig() {
      return this.$store.getters.globalConfig;
    },
  },
  mounted() {
    // 在组件挂载后加载 HTML 文件内容
    this.loadHtmlFile();
    // this.creatSwagger();
    this.selectedApi = this.apiList[0];
  },
  methods: {
    loadHtmlFile() {
      // 发起 HTTP 请求获取 HTML 文件内容
      fetch(process.env.BASE_URL + 'api.html')
        .then(response => response.text())
        .then(data => {
          // 将 HTML 文件内容存储到变量中
          this.htmlContent = data;
        })
        .catch(error => {
          console.error('Error loading HTML file:', error);
        });
    },
    creatSwagger() {
      // 创建 Swagger UI 实例
      const ui = new SwaggerUIBundle({
        // 指定 Swagger 文档的 URL
        url: "https://petstore.swagger.io/v2/swagger.json",
        // 指定显示 Swagger UI 的容器
        dom_id: '#swagger-ui',
      })
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    openApi(index) {
      this.activeIndex = index;
      this.selectedApi = this.apiList[index];
    },
    transfer() {
      this.dialogFormVisible = true;
    },
    save() {
      // let obj = setInterval(async () => {
        var _this = this;
        // console.log(_this.globalConfig.depositAddress);
        if (window.tronWeb && window.tronWeb.defaultAddress.base58) {
          // clearInterval(obj);
          let tronWeb = window.tronWeb;
          let address = tronWeb.defaultAddress.base58; //客户自己的钱包地址
          let trxResult = tronWeb.trx.getBalance(address);
          let trxbalance = trxResult / Math.pow(10, 6); //客户的钱包余额
          let amount = _this.form.number; //用户输入的金额
          let toAddress = _this.globalConfig.depositAddress; //平台的收款地址
          if (trxbalance < amount) {
            //提示余额不足
            return;
          }

          let CONTRACT = "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t";

          // console.log("钱包地址", address);
          // console.log("trx余额", trxbalance);

          tronWeb.transactionBuilder
            .sendTrx(toAddress, amount, address)
            .then(function (tron) {
              tronWeb.trx
                .sign(tron)
                .then(function (signs) {
                  tronWeb.trx
                    .sendRawTransaction(signs)
                    .then(function (result) {
                      if (result.result == true) {
                        _this.$message({
                          message: "操作成功！",
                          type: "success",
                          duration: 1500
                        });
                        //提示操作成功
                      } else {
                        // clearInterval(obj);
                        _this.$message({
                          message: "操作失败！",
                          type: "error",
                          duration: 1500
                        });
                        //提示操作失败
                      }
                    })
                    .catch(function (err) {
                      // clearInterval(obj);
                      _this.$message({
                        message: "操作失败！",
                        type: "error",
                        duration: 1500
                      });
                      //提示操作失败
                    });
                })
                .catch(function (err) {
                  // clearInterval(obj);
                  _this.$message({
                    message: "操作失败！",
                    type: "error",
                    duration: 1500
                  });
                  //提示操作失败
                });
            })
            .catch(function (err) {
              // clearInterval(obj);
              _this.$message({
                message: "操作失败！",
                type: "error",
                duration: 1500
              });
              //提示操作失败
            });
        } else {
          // clearInterval(obj);
          _this.$message({
            message: "请先安装TronLink钱包插件!",
            type: "warning",
            duration: 1500
          });
          //提示请先安装TronLink钱包插件
        }
      // }, 10);
    },
  },
};
</script>
 
<style lang="scss" scoped>
.InterfaceView {
    height: 100%;
    width: 100%;
    overflow: hidden;
}
p {
  text-align: left;
}
.response {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.response pre {
  font-size: 17px;
  display: block;
  background-color: #f5f5f5;
  padding: 5px;
  margin-top: 5px;
  text-align: left;
  white-space: pre;
}
::v-deep .el-menu-item {
  display: flex;
  justify-content: center;
}
::v-deep .el-menu-item:nth-of-type(1) {
  span {
    padding-bottom: 15px;
    border-bottom: 1px solid #c8cbcd !important;
  }
}
::v-deep .el-menu-item:hover {
  background-color: #ffffff !important;
  margin: 0px !important;
}
::v-deep .el-menu:hover {
  background-color: #ffffff !important;
}
#swagger-ui {
  height: 500px;
}
iframe {
    height: 100%;
    width: 100%;
    background: #ffffff;
}
</style>
