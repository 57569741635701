<template>
    <div class="transfer-rent">
      <el-card class="box-card">
        <el-radio-group
          class="transfer-type-group"
          style="margin: 0; width: 100%"
          v-model="controlType"
          @change="controlTypeChange"
        >
          <el-radio-button style="width: 50%" label="rent"
            >小时笔数代理</el-radio-button
          >
          <!-- <el-radio-button style="width: 50%" label="2"
            >包月笔数代理</el-radio-button
          > -->
          <el-radio-button style="width: 50%" label="3"
            >不限时笔数代理</el-radio-button
          >
        </el-radio-group>
        <el-alert
          v-if="controlType === 'rent'"
          style="margin: 30px 0"
          title="小时笔数说明"
          type="success"
          :closable="false"
          show-icon
        >
        <slot solt="title">
          转账TRX/U到地址会获得对应免费转账笔数，时效为一小时。
          <span style="color:red">当前平台 32000能量一小时价格为{{ initForm.price }}TRX。</span>
        </slot>
        </el-alert>
  
        <el-alert
          v-else-if="controlType === '2'"
          style="margin: 30px 0"
          title="包月笔数说明"
          :closable="false"
          type="success"
          show-icon
        >
        <slot solt="title">
          转账TRX/U到地址会获得每天免费转账笔数，时效为一个月。
          <span style="color:red">当前平台 一个月每天免费转账一笔价格为{{ initForm.price }}TRX。</span>
        </slot>
        </el-alert>
  
        <el-alert
          v-else
          style="margin: 30px 0"
          title="不限时笔数说明"
          :closable="false"
          type="success"
          show-icon
        >
        <slot solt="title">
          将委托65000能量到接收地址，接收地址每USDT转账一次计一笔费用，24小时内没有转账会重新记一笔滞留费，每次USDT转账后能量将在5秒内补充。
          <span style="color:red">当前平台 每笔价格为{{ initForm.price }}TRX。</span>
        </slot>
        </el-alert>
  
        <div>
          <el-button type="primary" size="mini" @click="openEditDialog()">新增</el-button>
           <el-table
          :data="hourtableData"
          style="width: 100%; min-height: 300px"
        >
          <el-table-column prop="address" label="转账地址" width="400">
          </el-table-column>
          <el-table-column prop="energyNum" label="能量数量">
          </el-table-column>
          <!-- <el-table-column prop="moneyType" label="类型" width="100">
            <template slot-scope="scope">
              {{ scope.row.moneyType == 1 ? 'TRX' : 'USDT' }}
            </template>
          </el-table-column> -->
          <el-table-column prop="fromAddress" label="TRX价格">
            <template slot-scope="scope">
              {{ scope.row.trxPrice }}
            </template>
          </el-table-column>
          <el-table-column prop="st" label="USDT价格">
            <template slot-scope="scope">
              {{ scope.row.usdtPrice }}
            </template>
          </el-table-column>
          <el-table-column prop="rate" label="最大翻倍"></el-table-column>
          <el-table-column prop="st" label="状态">
            <template slot-scope="scope">
              <el-tag size="mini" :type="scope.row.st ? '' : 'danger'">
                {{ scope.row.st ? '开启' : '关闭' }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作"  width="100">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="openEditDialog(scope.row)">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChangeHour"
          @current-change="handleCurrentHour"
          :current-page="paginationHour.currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="paginationHour.pageSize"
          layout="total, sizes, prev, pager, next"
          :total="paginationHour.total"
        >
        </el-pagination>
        </div>
        <!-- <TransferForm
            v-else
          :initForm="initForm"
          :controlType="controlType"
        ></TransferForm> -->
      </el-card>
  
      <div class="box" v-if="controlType != 3">
        <div
          style="display: flex; flex-wrap: wrap; gap: 10px; text-align: center"
        >
          <el-card
            class="top-box-right-top-item"
            style="border-left: 40px solid #409eff"
          >
            <p :style="{ fontSize: pageSize == 'mini' ? '20px' : '50px', fontWeight: '700', color: '#409eff'}">
              {{ Math.floor(money.dailiMoney) }}
            </p>
            <p style="font-size: 12px">地址收入</p>
          </el-card>
          <el-card
            class="top-box-right-top-item"
            style="border-left: 40px solid #e6a23c"
          >
            <p style="font-size: 50px; font-weight: 700; color: #e6a23c" :style="{ fontSize: pageSize == 'mini' ? '20px' : '50px'}">
              {{ Math.floor(money.platformMoney) }}
            </p>
            <p style="font-size: 12px">平台支出</p>
          </el-card>
          <el-card
            class="top-box-right-top-item"
            style="border-left: 40px solid #67c23a"
          >
            <p style="font-size: 50px; font-weight: 700; color: #67c23a" :style="{ fontSize: pageSize == 'mini' ? '20px' : '50px'}">
              {{ Math.floor(money.dailiMoney - money.platformMoney) }}
            </p>
            <p style="font-size: 12px">总收益</p>
          </el-card>
        </div>
      </div>
      <el-card style="flex: 1 1 600px" v-if="userInfo.username">
        <el-input
          placeholder="请输入转账地址"
          v-model="tableSearch"
          @keyup.enter.native="getTableList"
          style="width: 300px; text-algin: left"
          size="mini"
        >
          <i
            slot="suffix"
            class="el-input__icon el-icon-search"
            @click="getTableList"
          ></i>
        </el-input>
        <el-button type="primary" size="mini" :style="{marginLeft: pageSize == 'mini' ? '0px' : '15px', marginTop: pageSize == 'mini' ? '10px' : '0px' }" @click="getTableList">搜索</el-button>
        <div v-if=" controlType !=3 ">
          <div v-if="pageSize === 'mini'">
          <div class="order-page-list-item" v-for="(item, index) in tableData" :key="index">
            <div class="card-right-index">
              <div style="padding: 0px 10px">{{ index + 1 }}</div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">订单号</div>
              <div class="row-item-right">
                <span>{{ item.orderId }}</span>
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">地址收入</div>
              <div class="row-item-right">
                <span>{{ item.money + (item.moneyType == 1 ? 'TRX' : 'USDT') }}</span>
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">转账地址</div>
              <div class="row-item-right">
                <!-- <span>{{ item.fromAddress }}</span> -->
                <i
                  @click="copy(item.fromAddress)"
                  style="cursor: pointer; color: #409eff"
                  class="el-icon-document-copy"
                ></i>
                <span
                  style="
                    color: #409eff;
                    font-weight: 600;
                    display: inline-block;
                    height: 20px;
                    width: 80px;
                    vertical-align: sub;
                    margin-left: 5px;
                  "
                  class="ellipsis"
                  >{{ item.fromAddress }}</span
                >
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">代理地址</div>
              <div class="row-item-right">
                <!-- <span>{{ item.fromAddress }}</span> -->
                <i
                  @click="copy(item.toAddress)"
                  style="cursor: pointer; color: #409eff"
                  class="el-icon-document-copy"
                ></i>
                <span
                  style="
                    color: #409eff;
                    font-weight: 600;
                    display: inline-block;
                    height: 20px;
                    width: 80px;
                    vertical-align: sub;
                    margin-left: 5px;
                  "
                  class="ellipsis"
                  >{{ item.toAddress }}</span
                >
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">状态</div>
              <div class="row-item-right">
                <!-- <span>{{ item.st == 1 ? '已处理' : '失败' }}</span> -->
                <el-tag v-if="item.st == 0">
                  未处理
                </el-tag>
                <el-tag v-else-if="item.st == 1" type="success">
                  成功
                </el-tag>
                <el-tag v-else type='danger'>
                  失败
                </el-tag>
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">日期</div>
              <div class="row-item-right">
                {{ item.createTime }}
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">哈希</div>
              <div class="row-item-right">
                <el-button type="success" size="mini" plain @click="hxDeal(item.tid)">交易哈希</el-button>
              </div>
            </div>
          </div>
        </div>
  
        <el-table
          v-else
          v-loading="loading"
          :data="tableData"
          style="width: 100%; min-height: 300px;"
        >
          <!-- <el-table-column type="index" width="50"> </el-table-column> -->
          <el-table-column prop="orderId" label="订单号">
          </el-table-column>
          <!-- <el-table-column prop="moneyType" label="类型" width="100">
            <template slot-scope="scope">
              {{ scope.row.moneyType == 1 ? 'TRX' : 'USDT' }}
            </template>
          </el-table-column> -->
          <el-table-column prop="money" label="地址收入">
            <template slot-scope="scope">
              {{ scope.row.money + (scope.row.moneyType == 1 ? 'TRX' : ' USDT') }}
            </template>
          </el-table-column>
          <el-table-column prop="fromAddress" label="转账地址">
          </el-table-column>
          <el-table-column prop="toAddress" label="代理地址">
          </el-table-column>
          <el-table-column prop="st" label="状态" width="200px">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.st == 0">
                未处理
              </el-tag>
              <el-tag v-else-if="scope.row.st == 1" type="success">
                成功
              </el-tag>
              <el-tag v-else type='danger'>
                失败
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="日期" width="200px"></el-table-column>
          <el-table-column prop="tid" label="操作" width="200px">
            <template slot-scope="scope">
              <el-button type="success" size="mini" plain @click="hxDeal(scope.row.tid)">交易哈希</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next"
          :total="pagination.total"
        >
        </el-pagination>
        </div>
      </el-card>
  
      <div v-if="isSafari == 1" style="height:50px">
  
      </div>
  
      <!-- 编辑 -->
      <TransferFormEditDialog
        :dialogVisible="transferRentDialogVisible"
        :dialogForm="transferRentDialogForm"
        :pageSize="pageSize"
        @close="closeDialog"
        :title="transferRentDialogTitle"
      >
      </TransferFormEditDialog>
  
      <!-- 统计 -->
      <StatisticsDialog
        :dialogVisible="statisticsDialogVisible"
        :dialogForm="transferRentDialogForm"
        :pageSize="pageSize"
        @close="closeDialog"
      >
      </StatisticsDialog>
  
      
    </div>
  </template>
    
  <script>
  import { DDIC_ORDER_TYPE } from "@/utils/tools";
  import TransferForm from "./transfer-rent/form.vue";
  import TransferFormEditDialog from "./transfer-rent/edit-dialog.vue";
  import StatisticsDialog from "./transfer-rent/statistics-dialog.vue";
  import {
    hourInfo,
    monthInfo,
    hourData,
    monthData,
    hourRecord,
    monthRecord,
    oneInfo,
    oneLlist,
    oneOpen
  } from "@/api/login";
  export default {
    name: "TransferRentView",
    props: {
      pageSize: {
        type: String,
        default: "medium",
      },
    },
    components: { TransferForm, TransferFormEditDialog, StatisticsDialog },
    data() {
      return {
        isSafari: localStorage.getItem('isSafari'),
        paginationHour: {
          currentPage: 1, //页码
          pageSize: 10,
          total: 0,
        },
        initForm: {
          address: "",
          energyNum: 1,
          trxPrice: "",
          usdtPrice: "",
          superposition: 1,
          maxSuperposition: 30,
        },
        transferRentDialogVisible: false,
        transferRentDialogForm: {
          address: "",
          energyNum: 1,
          trxPrice: "",
          usdtPrice: "",
          superposition: 1,
          rate: '',
        },
        transferRentDialogTitle: "新增",
        controlType: "rent",
        loading: false,
        statisticsDialogVisible: false,
        money: {
          dailiMoney: 0,
          platformMoney: 0,
        },
        pagination: {
          currentPage: 1, //页码
          pageSize: 10,
          total: 0,
        },
        pagination1: {
          currentPage: 1, //页码
          pageSize: 10,
          total: 0,
        },
        tableSearch: "",
        oneTableData: [],
        oneLoading: false,
        hourtableData: [],
        tableData: []
      };
    },
    filters: {
      fifterType(val) {
        let value = "充值";
        switch(val) {
          case 1: value = "充值"; break;
          case 2: value = "api"; break;
          case 3: value = "小时代理"; break;
          case 4: value = "包月代理"; break;
          case 5: value = "手动租用"; break;
          case 6: value = "笔数委托"; break;
          case 7: value = "笔数滞留"; break;
          case 8: value = "备用api"; break;
        }
        return value;
      }
    },
    computed: {
      userInfo() {
        return this.$store.getters.userInfo;
      },
    },
    watch: {
      controlType() {
        if(this.controlType == '3') {
          
          this.$message({
              message: "正在开发中！",
              type: "warning",
              duration: 1500
            });
        } else {
          this.pagination= {
          currentPage: 1, //页码
          pageSize: 10,
          total: 0,
          },
          this.pagination1= {
            currentPage: 1, //页码
            pageSize: 10,
            total: 0,
          }
          this.tableSearch="",
          this.getTableData();
          if(this.controlType!= '3') this.getData();
          this.getTableList();
        }
      },
    },
    methods: {
        controlTypeChange(val) {
            if(val == '3') this.controlType = "rent";
        },
      //获取订单统计
      getData() {
        // if(!sessionStorage.getItem('isToken')) {}
        let url = hourData;
        if (this.controlType != "rent") url = monthData;
        url().then((res) => {
          let { code, data } = res.data;
          if (code == 200) {
            this.money = data;
          }
        });
      },
      getTableList() {
        let tableUrl = hourRecord;
        if (this.controlType == "2") {
          tableUrl = monthRecord;
        } else if(this.controlType == "3") {
          tableUrl = oneLlist;
        }
        if(this.controlType != "3") {
          let { pageSize, currentPage } = this.pagination;
          var data = {
            pageSize: currentPage,
            limit: pageSize,
            content: this.tableSearch,
          };
        } else {
          let { pageSize, currentPage } = this.pagination1;
          var data = {
            pageSize: currentPage,
            limit: pageSize,
            content: this.tableSearch,
          };
        }
        tableUrl(data).then((res) => {
          let { code, data } = res.data;
          if (code == 200) {
            if(this.controlType != "3") {
              this.tableData = data.list;
              this.pagination.total = data.total;
            } else {
              this.oneTableData = data.list;
              this.pagination1.total = data.total;
            }
            
          }
        });
      },
      // 获取页面表格数据
      getTableData() {
        let url = hourInfo;
        if (this.controlType == "2") {
          url = monthInfo;
        } else if(this.controlType == "3") {
          url = oneInfo
        }
        url().then((res) => {
          let { code, data } = res.data;
          if (code == 200) {
            if(this.controlType == 'rent') {
              this.hourtableData = data.list;
              this.paginationHour.total = data.total;
              this.initForm.price = data.price;
            } else {
              this.initForm = data;
            }
          }
        });
        // console.log("getTableData");
        this.loading = true;
        this.loading = false;
      },
      openEditDialog(row) {
        if(row) {
          this.transferRentDialogForm = JSON.parse(JSON.stringify(row));
          this.transferRentDialogTitle = "修改";
        } else {
          this.transferRentDialogTitle = "新增";
          this.transferRentDialogForm = {
            address: "",
            energyNum: 1,
            trxPrice: "",
            usdtPrice: "",
            superposition: 1,
            rate: '',
          };
        }
        this.transferRentDialogVisible = true;
      },
      closeDialog() {
        this.transferRentDialogVisible = false;
        this.statisticsDialogVisible = false;
        this.getTableData();
      },
      openStatisticsDialog(row) {
        this.transferRentDialogForm = row;
        this.statisticsDialogVisible = true;
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val;
        this.getTableList();
        // console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.pagination.currentPage = val;
        this.getTableList();
        // console.log(`当前页: ${val}`);
      },
      handleSizeChange1(val) {
        this.pagination1.pageSize = val;
        this.getTableList();
        // console.log(`每页 ${val} 条`);
      },
      handleCurrentChange1(val) {
        this.pagination1.currentPage = val;
        this.getTableList();
        // console.log(`当前页: ${val}`);
      },
      handleSizeChangeHour(val) {
        this.paginationHour.pageSize = val;
        this.getTableData();
        // console.log(`每页 ${val} 条`);
      },
      handleCurrentHour(val) {
        this.paginationHour.currentPage = val;
        this.getTableData();
        // console.log(`当前页: ${val}`);
      },
      oneState(row) {
        var data = {
          st: row.st,
          address: row.address
        }
        oneOpen(data).then(res =>{
          let { code } = res.data;
          if(code == 200) {
            this.$message({
              message: "操作成功！",
              type: "success",
              duration: 1500
            });
          }
        })
      },
      //交易哈希
      hxDeal(id) {
        window.open("https://tronscan.org/#/transaction/" + id);
      },
    },
    mounted() {
      this.getTableData();
      this.getData();
      this.getTableList();
    },
    destroyed() {},
  };
  </script>
    
  <style lang="scss" scoped>
  .transfer-rent {
    display: flex;
    margin: 15px !important;
    text-align: left;
    font-size: 14px;
    flex-wrap: wrap;
    gap: 20px;
    overflow: hidden;
  }
  .order-page-list-item {
    width: 100%;
    overflow-x: auto;
  }
  .box-card {
    width: 100%;
  }
  .box {
    width: 100%;
    ::v-deep .el-card {
      flex: 1;
    }
  }
  ::v-deep .el-pagination {
    margin-top: 10px;
    text-align: end;
  }
  .top-box-right-top-item {
    ::v-deep .el-card__body {
      height: 100%;
      padding: 0px;
      padding-top: 20px;
      min-height: 75px;
      line-height: 35px;
    }
  }
  ::v-deep .el-tag {
    width:58px;
    text-align:center;
  }
  </style>
  
  <style lang='scss'>
  .transfer-type-group {
    .el-radio-button__inner {
      width: 100%;
    }
  }
  </style>