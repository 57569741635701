<template>
  <div class="balance-record">
    <el-card class="box-card">
      <div class="search">
        <div style="flex: 1 1 230px">
          <el-input
            v-model="balanceRecord.content"
            placeholder="请输入订单号"
          ></el-input>
        </div>
        <div style="flex: 1 1 230px">
          <el-select
            v-model="balanceRecord.orderType"
            placeholder="请选择订单类型"
          >
            <el-option
              v-for="item in this.DDIC_ORDER_TYPE"
              :label="item.label"
              :value="item.value"
              :key="item.label"
            ></el-option>
          </el-select>
        </div>
        <div style="flex: 1 1 230px">
          <el-date-picker
            v-model="balanceRecord.time"
            type="date"
            placeholder="请选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div style="flex: 1 1 230px; display: flex; align-items: center">
          <el-button
            round
            type="primary"
            @click="search"
            style="width: inherit; flex: 1 1 0"
            >搜索</el-button
          >
          <el-button
            round
            type="warning"
            @click="reset"
            style="width: inherit; flex: 1 1 0"
            >重置</el-button
          >
        </div>
      </div>

      <div v-if="pageSize === 'mini'">
        <div
          style="position: relative; margin-top: 40px"
          v-for="(item, index) in tableData"
          :key="index"
        >
          <div class="mini-tip" style="background-color: #409eff !important">
            能量
          </div>
          <div class="card-right-index" style="z-index: 2">
            <div style="padding: 0px 10px">{{ index + 1 }}</div>
          </div>
          <el-card class="order-page-list-item">
            <!-- <div class="list-row-item">
              <div class="row-item-left">订单ID</div>
              <div class="row-item-right">
                <el-tag type="primary" size="mini" style="font-weight: 600">{{
                  item.content
                }}</el-tag>
              </div>
            </div> -->
            <div class="list-row-item">
              <div class="row-item-left">订单类型</div>
              <div class="row-item-right">
                <el-tag type="primary" size="mini" style="font-weight: 600">{{
                  item.orderType | fifterType(item.orderType)
                }}</el-tag>
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">订单号</div>
              <div class="row-item-right">
                <el-tag type="primary" size="mini" style="font-weight: 600">{{
                  item.orderId
                }}</el-tag>
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">金额</div>
              <div class="row-item-right">
                <el-tag type="primary" size="mini" style="font-weight: 600">{{
                  item.money + ' TRX'
                }}</el-tag>
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">余额</div>
              <div class="row-item-right">
                <el-tag type="primary" size="mini" style="font-weight: 600">{{
                  item.balance + ' TRX'
                }}</el-tag>
              </div>
            </div>
            <!-- <div class="list-row-item">
              <div class="row-item-left">数量X单价（SUN）X天数≈订单金额</div>
              <div class="row-item-right">
                <el-tag type="warning" size="mini" style="font-weight: 600">{{
                  item.orderMoney
                }}</el-tag>
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">余额变更前</div>
              <div class="row-item-right">
                <el-tag size="mini" type="warning" style="font-weight: 600">{{
                  item.beforeBalance
                }}</el-tag>
              </div>
            </div>
            <div class="list-row-item">
              <div class="row-item-left">余额变更后</div>
              <div class="row-item-right">
                <el-tag size="mini" type="warning" style="font-weight: 600">{{
                  item.afterBalance
                }}</el-tag>
              </div>
            </div> -->
            <div class="list-row-item">
              <div class="row-item-left">创建时间</div>
              <div class="row-item-right">
                {{ item.createTime }}
              </div>
            </div>
          </el-card>
        </div>
      </div>

      <el-table
        v-else
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column type="index" width="50"> </el-table-column>
        <el-table-column prop="orderType" label="订单类型">
          <template slot-scope="scope">
             <el-tag effect="dark" type="primary">{{ scope.row.orderType | fifterType(scope.row.orderType) }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="orderId" label="订单号">
          <template slot-scope="scope">
            <el-tag type="primary" style="font-weight: 600">{{
              scope.row.orderId
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="money" label="金额">
          <template slot-scope="scope">
            {{ scope.row.money + ' TRX'}}
          </template>
        </el-table-column>
        <el-table-column prop="money" label="余额">
          <template slot-scope="scope">
            {{ scope.row.balance + ' TRX'}}
          </template>
        </el-table-column>
        <el-table-column prop="st" label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.st ? 'success' : 'danger'" style="font-weight: 600">{{
              scope.row.st ? '成功' : '失败'
            }}</el-tag>
          </template>
        </el-table-column>
        <!-- <el-table-column
          prop="orderMoney"
          label="数量X单价（SUN）X天数≈订单金额"
        >
          <template slot-scope="scope">
            <el-tag type="warning" style="font-weight: 600">{{
              scope.row.orderMoney
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="beforeBalance" label="余额变更前">
          <template slot-scope="scope">
            <el-tag type="warning" style="font-weight: 600">{{
              scope.row.beforeBalance
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="afterBalance" label="余额变更后">
          <template slot-scope="scope">
            <el-tag type="warning" style="font-weight: 600">{{
              scope.row.afterBalance
            }}</el-tag>
          </template>
        </el-table-column> -->
        <el-table-column prop="createTime" label="创建时间" width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pagination.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="pagination.total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { DDIC_ORDER_TYPE } from "@/utils/tools";
import { moneyRecord } from "@/api/login";
export default {
  name: "BalanceRecordView",
  props: {
    pageSize: {
      type: String,
      default: "medium",
    },
  },
  data() {
    return {
      loading: false,
      balanceRecord: {
        content: "",
        orderType: 0,
        time: "",
      },
      tableData: [],
      DDIC_ORDER_TYPE,
      pagination: {
        currentPage: 1, //页码
        pageSize: 10,
        total: 0,
      },
    };
  },
  filters: {
    fifterType(val) {
      let value = "充值";
      switch(val) {
        case 1: value = "充值"; break;
        case 2: value = "api"; break;
        case 3: value = "小时代理"; break;
        case 4: value = "包月代理"; break;
        case 5: value = "手动租用"; break;
        case 6: value = "笔数委托"; break;
        case 7: value = "笔数滞留"; break;
        case 8: value = "备用api"; break;
      }
      return value;
    }
  },
  methods: {
    // 搜索数据
    search() {
      // console.log("search");
      this.getTableData();
    },
    // 重置数据
    reset() {
      // console.log("reset");
      this.balanceRecord = {
        content: "",
        orderType: "0",
        time: "",
      };
      this.getTableData();
    },
    // 获取页面表格数据
    getTableData() {
      // console.log("getTableData");
      this.loading = true;
      let { pageSize, currentPage } = this.pagination;
      let { content,orderType,time } = this.balanceRecord;
      var data = {
        pageSize: currentPage,
        limit: pageSize,
        content,
        orderType,
        time
      };
      moneyRecord(data).then(res =>{
        let { code, data } = res.data;
        if (code == 200) {
          this.tableData = data.list;
          this.pagination.total = data.total;
        }
      })
      // todo 从api请求数据
      // this.tableData = [
      //   {
      //     "content": 1709818126263879,
      //     "orderType": "ENERGY",
      //     "orderMoney": "32000 X 80 X 1 / 1000000 ≈ 2.56 TRX",
      //     "beforeBalance": "46.2 TRX",
      //     "afterBalance": "43.64 TRX",
      //     "firstOrder": 1,
      //     "createTime": "2024-03-07 21:28:46"
      //   },
      //   {
      //     "content": 1709451153863654,
      //     "orderType": "ENERGY",
      //     "orderMoney": "32000 X 47 / 1000000 ≈ 1.5 TRX",
      //     "beforeBalance": "47.7 TRX",
      //     "afterBalance": "46.2 TRX",
      //     "firstOrder": 1,
      //     "createTime": "2024-03-03 15:32:33"
      //   },
      //   {
      //     "content": 1709451105850458,
      //     "orderType": "autoTypeOne",
      //     "orderMoney": "65000 X 36 X 1 / 1000000 ≈ 2.3 TRX",
      //     "beforeBalance": "50 TRX",
      //     "afterBalance": "47.7 TRX",
      //     "firstOrder": 1,
      //     "createTime": "2024-03-03 15:31:45"
      //   },
      //   {
      //     "content": 1709380002698473,
      //     "orderType": "charge",
      //     "orderMoney": "50 TRX",
      //     "beforeBalance": "0 TRX",
      //     "afterBalance": "50 TRX",
      //     "firstOrder": 0,
      //     "createTime": "2024-03-02 19:46:42"
      //   }
      // ]
      this.loading = false;
    },
    // 计算订单类型
    computedOrderType(type) {
      const typeInfo = DDIC_ORDER_TYPE.filter((item) => item.value === type)[0];
      return Object.assign(
        {
          color: "#409eff",
        },
        typeInfo
      );
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val;
      this.getTableData();
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.getTableData();
      // console.log(`当前页: ${val}`);
    },
  },
  mounted() {
    this.getTableData();
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.balance-record {
  margin: 15px;
  text-align: left;
  font-size: 14px;
  .search {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    justify-content: space-around;

    .el-input,
    .el-select {
      width: 100%;
    }
  }
  ::v-deep .el-pagination {
    margin-top: 10px;
    text-align: end;
  }
}
</style>