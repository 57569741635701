import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { getConfig,logout, userInfo } from '@/api/login'
import request from '@/api/index'
import Cookies from 'js-cookie';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      "username": "",
      "balance": '',
      apiKey: '',
      address: ''
    },
    globalConfig: {
      "id": '',
      "trxEnergy": 0,
      "costEnergy": 0,
      "tgService": "",
      "trxAddress": "TB7B9FNHeZU71wR9UsjyKxVivszkYRsokx",
      "platformPrice": 0,
      "apiPrice": 0,
      "totalEnergyNum": 0,
      "leftEnergyNum": 0
    },
    orderSuccessList: [],
  },
  getters: {
    userInfo(state) {
      return state.user
    },
    globalConfig(state) {
      return state.globalConfig
    },
    orderSuccessList(state) {
      return state.orderSuccessList
    },
  },
  mutations: {
    setUserInfo (state, user) {
      state.user = user
    },
    setGlobalConfig(state, globalConfig) {
      state.globalConfig = globalConfig
    },
    setOrderSuccessList(state, orderSuccessList) {
      state.orderSuccessList = orderSuccessList;
    },
  },
  actions: {
    async getConfig ({ commit }) {
     const {data} =  await getConfig({token: Cookies.get("isToken")})
    //  console.log(data)
     commit('setGlobalConfig', data.data.eConfig)
    },
    async getUserInfo ({ commit }) {
      return new Promise((resolve,reject) => {
        // if(sessionStorage.getItem("isToken")) {
          userInfo().then(res =>{
            let { data,code } = res.data;
            if(code == 200) {
              let { account,address,apiKey,trx } = data;
              let userInfo = {
                "username": account,
                "balance": trx,
                apiKey,
                address
              }
              Cookies.set('userInfo', JSON.stringify(userInfo));
              commit('setUserInfo', {
                "username": account,
                "balance": trx,
                apiKey,
                address
              })
              resolve()
            }
          }).catch(err =>{
            reject();
          }) 
        // }
      })
      
    },  
    async login ({ commit },state) {
      let { account,token } = state;
      commit('setUserInfo', {
        "username": account,
        "balance": "--"
      })
    },
    async register  ({ commit }) {
    },
    // 登出
    async logout({commit}) {
      commit('setUserInfo', {
        "username": "",
        "balance": 0
      })
      logout().then(res =>{
        let { code } = res.data;
        if(code == 200) {
          Cookies.remove('isToken');
          Cookies.remove("userInfo");
          commit('setUserInfo', {
            "username": "",
            "balance": 0
          })
          // request = axios.create({
          //   headers: {
          //     'Content-Type': 'application/json;charset=UTF-8',
          //     'X-Requested-With': 'XMLHttpRequest',
          //     'X-AppCode': 'jgpt-im-service',
          //   }
          // })
        }
      })
    },
    
  },
  modules: {
  }
})
