<template>
  <div class="main-box">
    <div :class=" isSafari == 1 ? 'home isSafari' : 'home'">
      <div class="show-main-text gradient-text">TRON 能量交易平台</div>
      <div class="show-main-text gradient-text" style="margin-top: 20px;">便宜、便捷、快速、高效、安全</div>
      <div class="ciyaowenzi-color"> peee.io 是全网最低价的能量快速、稳定租赁平台。提供小额api接口、大额速充、小时/包月笔数代理、功能定制服务。</div>
      <div style="display: flex; align-items: center; justify-content: center; margin-top: 50px; flex-wrap: wrap;">
        <el-card class="show-card" shadow="hover">
          <div slot="header" class="title">
            <span class="number-text">1</span>
            <span class="gradient-text pay-title">平台下单</span>
          </div>
          <div class="card-context">
            <img style="width:200px;height:200px;object-fit: contain;" src="../assets/img/shopping.png" alt="">
            <ul class="card-ul">
              <li><span>操作简便</span></li>
              <li><span>数据详细</span></li>
              <li><span>支持扫码付款</span></li>
              <li><span>Dapp付款</span></li>
            </ul>
          </div>
          <div class="card-button">
            <el-button class="button-gradient" type="primary" @click="place">前往下单</el-button>
          </div>
        </el-card>

        <el-card class="show-card" shadow="hover">
          <div slot="header" class="title">
            <span class="number-text">2</span>
            <span class="gradient-text pay-title">TG机器人下单</span>
          </div>
          <div class="card-context">
            <img style="width:200px;height:200px;object-fit: contain;" src="@/assets/img/robot.png" alt="">
            <ul class="card-ul">
              <li><span>对接机器人自助下单</span></li>
              <li><span>简便，高效，自助</span></li>
            </ul>
          </div>
          <div class="card-button">
            <el-button class="button-gradient" type="primary" @click="openNewPage('https://t.me/okxxio')">波场助手机器人</el-button>
            <el-button class="button-gradient" type="primary" @click="openNewPage('https://t.me/okxxio')">TG机器人下单</el-button>
          </div>
        </el-card>

        <el-card class="show-card" shadow="hover">
          <div slot="header" class="title">
            <span class="number-text">3</span>
            <span class="gradient-text pay-title">扫码转账下单</span>
          </div>
          <div class="card-context">
            <img style="width:200px;height:200px;object-fit: contain;" src="@/assets/img/open.png" alt="">
            <ul class="card-ul">
              <li title="TRC20转账USDT每笔交易至少消耗32000能量"><span>TRC20转账USDT每笔交易至少消耗32000能量</span></li>
              <li><span>扫码转账获取能量，不到账包赔</span></li>
              <li @click="openMorePayInfo" style="text-decoration: underline;cursor: pointer;"><span style="color: #409eff;">点击查看更多信息</span></li>
            </ul>
          </div>
          <div class="card-button">
            <el-button class="button-gradient" type="primary">复制扫码转账地址</el-button>
            <el-button class="button-gradient" type="primary">打开扫码转账地址</el-button>
            <!-- <el-button class="button-gradient" type="primary" @click="copyPayPath()">复制扫码转账地址</el-button>
            <el-button class="button-gradient" type="primary" @click="openPayPath()">打开扫码转账地址</el-button> -->
          </div>
        </el-card>

        <el-card class="show-card" shadow="hover">
          <div slot="header" class="title">
            <span class="number-text">4</span>
            <span class="gradient-text pay-title">平台API下单</span>
          </div>
          <div class="card-context">
            <img style="width:200px;height:200px;object-fit: contain;" src="@/assets/img/api.png" alt="">
            <ul class="card-ul">
              <li><span>对接平台API</span></li>
              <li><span>下单更高效</span></li>
            </ul>
          </div>
          <div class="card-button">
            <el-button class="button-gradient" type="primary" @click="jumpTo('interface')">前往了解</el-button>
          </div>
        </el-card>
      </div>

      <!-- FAQ -->
      <el-card shadow="always" style="margin: 30px auto;border-radius: 10px;width: 100%;">
        <div slot="header" class="title" style="font-size: 30px;font-weight: 700;">
          FAQ.
        </div>
        <div class="card-context">
          <el-collapse accordion>
            <el-collapse-item>
              <template slot="title">
                <span  class="faq-title gradient-text">什么是能量?能量是免费的吗？</span>
              </template>
              <div class="faq-content"> 
                TRON网络中的资源有3种:带宽,能量,存储。得益于波场独有的设计模型,TRON网络中的存储资源几乎是无限的。<br> 
                TRON网络引入了Bandwidth Point 和 Energy 两种可以进行量化的资源。其中Bandwidth Point表示带宽资源,Energy表示能量资源。<br> 
                我们在比特币、以太坊上转账时需要通过 BTC、ETH 来支付手续费。在波场进行转账时消耗的是带宽和能量。<br > 
                BTC, ETH, and TRX are all main network tokens, and tokens created based on these main networks are equivalent to renting in the home of the main network token landlord, and the method of paying the transaction fee is determined by the main network landlord.<br> 
                比特币和以太坊立下的规矩是,代币转账需要用 BTC 和 ETH 来支付,而波场立下的规矩则是用能量和带宽来支付。<br> 
                波场上有两类常见租客(两类常见的代币格式):TRC-10 和 TRC-20。转账 TRX 和 TRC-10 代币时需要消耗带宽,转账 TRC-20 代币时需要消耗带宽和能量。<br>
                如果你不知道代币格式,可以在资产首页点击该代币,进入交易记录界面后再点击右上角,进入 TRONSCAN 即可查看其代币格式,例如波场上的 USDT 就是 TRC-20 格式。<br> 
                波场账户能量不是免费的,需要冻结TRX获取能量。也可以向peee.io能量交易平台租能量。假如账户中有100万能量,使用完毕后,会慢慢恢复,24小时恢复满100万。冻结(租用)默认最少1小时, 当1小时后账户取消冻结(租用到期)时,通过冻结(租用)获得的能量也将自动清零。 
              </div>
            </el-collapse-item>
            
            <el-collapse-item>
              <template slot="title">
                <span  class="faq-title gradient-text">能量有什么用？</span>
              </template>
              <div class="faq-content"> 
                能量主要可在以下几种场景用于消耗(以下消耗数据为链上数据概揽,为大概数据,实际准确消耗数据需操作之后区块浏览器查询所得数据。)<br> 
                TRC20 消耗<br> 
                TRC20代币,消耗带宽和能量,例如USDT是20代币,转账一笔需消耗31895能量和345带宽,需要抵扣能量13.39TRX和带宽0.345TRX左右。<br> 
                智能合约 消耗<br> 调用智能合约,需消耗带宽和能量。例如SUNSWAP买卖交易,均是智能合约交互,需消耗账户中的能量和带宽。<br>
              </div>
            </el-collapse-item>

            <el-collapse-item>
              <template slot="title">
                <span  class="faq-title gradient-text">如何获得能量？</span>
              </template>
              <div class="faq-content"> 如果你想获得能量,有三种方法。<br> 
                方法一:燃烧 TRX<br> 
                如果账号没有足够能量,转账时会自动燃烧 TRX 以抵扣转账所需的带宽和能量。相比消耗能量,燃烧TRX显然并不划算。<br> 
                方法二:冻结 TRX<br> 
                点击资产首页的能量 / 带宽,进入 Tron 资源管理界面,选择想获得的资源类型,输入需冻结的 TRX 数量,获得的能量 = 冻结的TRX / 全网获得能量冻结的总TRX * 总能量限制。(这是基于冻结的TRX为所有用户平均分配的固定能量),冻结后的 TRX 在 3 天后可以解冻取回。如果您有大量闲置TRX,可以考虑这种方法。<br> 方法三:租用能量<br> 可以通过本平台进行能量租赁的方式来节省手续费,能量租赁既不需要冻结大量TRX,也不用燃烧更多TRX,还可节省至少80%的手续费,以租用100万能量为例,若直接燃烧TRX,3天需要燃烧大约1266TRX,使用用本平台租用100万能量只需要120TRX左右,节省了90%≈1140TRX。 
              </div>
            </el-collapse-item>
            
            <el-collapse-item>
              <template slot="title">
                <span  class="faq-title gradient-text">如何租用能量？</span>
              </template>
              <div class="faq-content"> 
                本平台提交4种下单方式。
                <span class="error-color" style="font-size: 18px; font-weight: 500; cursor: pointer;color:red;display:inline-block;border-bottom:1px solid red" @click="scrollToTop()">查看</span>
              </div>
            </el-collapse-item>

            <el-collapse-item>
              <template slot="title">
                <span  class="faq-title gradient-text">支付成功后,能量什么时候到账？</span>
              </template>
              <div class="faq-content"> 
                付款完成后,能量最快3秒内到账。由于广播延迟等原因,极少数情况,会存在5到10分钟的延迟。 
              </div>
            </el-collapse-item>
            
            <el-collapse-item>
              <template slot="title">
                <span  class="faq-title gradient-text">能量使用后多久能恢复满？？</span>
              </template>
              <div class="faq-content"> 
                能量使用后将会在24小时内恢复满。 
              </div>
            </el-collapse-item>

            <el-collapse-item>
              <template slot="title">
                <span  class="faq-title gradient-text">订单可以撤回吗？</span>
              </template>
              <div class="faq-content"> 
                订单生成后,暂不支持撤回。 
              </div>
            </el-collapse-item>

          </el-collapse>
        </div>
      </el-card>
      <div class="tag">COPYRIGHT ©2022/24 peee.io_v2.9</div>


      <!-- 扫码转账下单-点击查看更多信息 -->
      <el-dialog
        title="波场链转账USDT免手续费（欢迎咨询免费送一笔能量体验）"
        :visible.sync="morePayInfoDialogVisible"
        :append-to-body="true"
        custom-class="custom-dialog-class">
        <div>
          <ul>
            <li>转账2个trx给扫码地址，等3秒钟再去转u，可以免手续费，有问题包赔</li>
            <li>2 trx：对面有u地址 消耗3.2w能量</li>
            <li>4 trx：对面没u地址 消耗6.5w能量</li>
            <li>能量有效期一个小时</li>
            <li>下单地址：（注意能量会自动转回转账的地址）</li>
            <li>可叠加至60trx 就是30个32000能量</li>
            <li>下单满8笔 可送7位豹子波场地址靓号</li>
          </ul>
        </div>
      </el-dialog>   

      <!-- 扫码转账下单-打开扫码转账 -->
      <el-dialog
        :visible.sync="openPayPathVisible"
        :append-to-body="true"
        custom-class="pay-path-dialog"
        :width="pageSize =='mini' ? '200px' : '400px'"
        >
        <div>
          <img src="@/assets/img/qrcode.png" alt="" :class="pageSize == 'mini' ? 'openPayImg' : ''">
        </div>
      </el-dialog>   
    </div>
  </div>
</template>

<script>
import router from '@/router';

export default {
  name: 'HomeView',
  components: {
  },
  props: {
    pageSize: {
      type: String,
      default: "medium",
    },
  },
  data(){
    return {
      isSafari: localStorage.getItem('isSafari'),
      morePayInfoDialogVisible: false,
      openPayPathVisible: false,
      // 支付地址
      payUUid: "TB7B9FNHeZU71wR9UsjyKxVivszkYRsokx",
      openPayWidth: "400px"
    }
  },
  watch: {
    // pageSize() {
    //   debugger
    //   if(this.pageSize == 'mini') {
    //     this.openPayWidth = '200px'
    //   } else {
    //     this.openPayWidth = '400px'
    //   }
    // }
  },
  methods: {
    // 跳转到其他页面
    jumpTo: (routeName) =>{
      // router.push({path: routeName})
      window.open('https://t.me/' + this.globalConfig.tgService)
    },
    // 打开新tab页
    openNewPage: (url) =>{
      window.open(url)
    },
    // 点击查看更多信息
    openMorePayInfo: function() {
      this.morePayInfoDialogVisible = true
    },
    // 复制扫码转账地址
    copyPayPath: function(){
      const input = document.createElement("input"); // 创建输入框元素   
      input.value = this.globalConfig.trxAddress//this.payUUid; // 设置输入框的值为要复制的内容
      document.body.appendChild(input); // 将输入框添加到页面中
      input.select(); // 选中输入框中的内容
      if (document.execCommand('copy')) { // 如果复制成功
        this.$message({
          message: '复制成功',
          type: 'success',
          duration: 1500
        });
      } else { this.$message({
          message: '复制失败',
          type: 'error',
          duration: 1500
        });
      }
      
      document.body.removeChild(input); // 移除输入框
    },
  
    //  
    openPayPath: function() {
      this.openPayPathVisible = true
    },

    //回到页面顶部
    scrollToTop() {
      // 滚动到页面顶部
      document.documentElement.scrollTop = 0; // Chrome、Firefox、IE、Opera、Safari
      document.body.scrollTop = 0; // 兼容旧版 IE
    },
    //下单
    place() {
      this.$router.push('/pay')
      // 滚动到页面顶部
      document.documentElement.scrollTop = 0; // Chrome、Firefox、IE、Opera、Safari
      document.body.scrollTop = 0; // 兼容旧版 IE
    }
  },
  computed: {
    globalConfig() {
      return this.$store.getters.globalConfig
    }
  }
}
</script>

<style lang="scss" scoped>

.main-box {
  background-image: url('@/assets/img/homeBack.jpg');
  // padding: 60px 0 0 0;
  position: relative;
  z-index: 0;
  transition: transform .7s,width .7s;
  // min-height: 100vh;
  background-position: 50%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  backdrop-filter: blur(100px);
  overflow: hidden;
}
.home {
  box-sizing: border-box;
  padding: 80px 20px 20px 20px;
  text-align: center;
  overflow-x: auto;
  overflow-y: hidden;
  .gradient-text{
    background-clip: text;
    color: transparent;
    background-image: linear-gradient(0deg,#4481eb 0,#04befe);
  }
  .show-main-text{
    font-size: 46px;
    font-weight: 800;
  }
  .ciyaowenzi-color{
    font-size: 20px;
    font-weight: 600;
    margin: 20px 0px;
    color: #909399;
  }
  .show-card{
    position: relative;
    width: 400px;
    height: 550px;
    margin: 10px 30px;
    border-color: #e3e5e8;
    border-radius: 25px;
    border: 1px solid #ebeef5;
    background-color: #fff;
    overflow: hidden;
    color: #303133;
    transition: .3s;
    .title{
      display: flex;
      align-items: left;
      font-size: 20px;
      font-weight: 600;
    }
    .number-text{
      background: #409eff;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 4px rgba(0,0,0,.12), 0 0 6px rgba(0,0,0,.04);
    }
    .pay-title{
      margin-left: 20px;
    }

    .card-ul{
      margin: 0 20px;
      list-style-type: disc;
      &>li{
        color: #409eff;
        font-size: 25px;
        text-align: left;
        &>span{
          display: block;
          color: #909399;
          font-size: 18px;
          font-weight: 500;
          line-height: 36px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .card-button{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      box-sizing: border-box;
      padding: 20px 10px;
      .el-button+.el-button{
        margin-top: 5px;
        margin-left: 0
      }
    }

  }
  .faq-title{
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
  }
  .faq-content{
    font-size: 18px;
    font-weight: 500;
    color: #909399;
    text-align: left;
  }
}

.isSafari {
  // padding: 140px 20px 20px 20px;
}

</style>

<style lang="scss">
  .custom-dialog-class{
    .el-dialog__header .el-dialog__title{
      color:#409eff;
      font-size: 24px;
      font-weight: bold;
    }
    .el-dialog__body{
      padding-top:0;
      ul{
        margin: 20px;
        font-size: 18px;
        color: #606266;
        list-style-type: disc;
        &>li{
          margin: 10px 0
        }
      }
    }
  }
  .pay-path-dialog{
    .el-dialog__header{
      display: none;
    }
    .el-dialog__body{
      padding: 0
    }
  }
  .openPayImg {
    width: 200px;
    height: 300px;
  }
  .tag {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #909399;
  }
</style>